import ApiService from "@/common/api.service";
import { FIND_MARCACAO, FIND_CONTRATO, ACCT_CONTRATO } from "./actions.type";


const state = {};

const getters = {};

const actions = {
    [FIND_MARCACAO](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/getMarcacao/${params}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [FIND_CONTRATO](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/getContrato/${params}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [ACCT_CONTRATO](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/aceptContrato/${params}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }, 
};

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};