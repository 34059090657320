import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{ path: '/', component: () => import('../views/HomeView.vue') },
		{ path: '/login', component: () => import('../views/Auth/LoginView.vue') },
		{ path: '/signup', component: () => import('../views/Auth/SignupView.vue') },
		{ path: '/recovery', component: () => import('../views/Auth/RecoveryView.vue') },
		{ path: '/Telemedicina', component: () => import('../views/TelemedicinaView.vue') },
		{ path: '/Telemedicina/ComoRetirarMedicamentos', component: () => import('../views/Telemedicina/ComoRetirarMedicamentosView.vue')},
		{ path: '/Beneficios', component: () => import('../views/BeneficiosView.vue') },
		{ path: '/Beneficios/Cinemas', component: () => import('../views/Beneficios/CinemasView.vue') },
		{ path: '/OutrosProdutos', component: () => import('../views/OutrosProdutosView.vue') },
		{ path: '/Medicamentos', component: () => import('../views/MedicamentosView.vue') },
		{ path: '/Medicamentos/Cobertos', component: () => import('../views/Medicamentos/CobertosView.vue') },
		{ path: '/Medicamentos/Farmacias', component: () => import('../views/Medicamentos/FarmaciasView.vue') },
		{ path: '/Medicamentos/RetirarMedicamentos', component: () => import('../views/Medicamentos/RetirarMedicamentos.vue') },
		{ path: '/Site', component: () => import('../views/SiteView.vue') },
		{ path: '/Consultas', component: () => import('../views/ConsultasView.vue') },
		{ path: '/Cartao', component: () => import('../views/CartaoView.vue') },
		{ path: '/ClubeAssist', component: () => import('../views/ClubeAssistView.vue') },
		{ path: '/PoliticaPrivacidade', component: () => import('../views/PoliticaPrivacidadeView.vue') },
	],
});

export default router;
