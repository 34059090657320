import cookie from 'vue-cookies'
import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import pkg from '@/../package.json';

import { LOGIN, SIGNUP, LOGOUT } from "./actions.type";
import { SET_AUTH, PURGE_AUTH } from "./mutations.type";

const USER_COOKIE = `${pkg.name}_user`;

const state = {
    user: cookie.get(USER_COOKIE),
    isAuthenticated: cookie.get(USER_COOKIE)?.cpf.length === 11
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    }
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post('/login', credentials)
                .then(({ data }) => {
                    data.cpf = data.cpf.replace(/\D/g, '');
                    context.commit(SET_AUTH, data);
                    resolve();
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [SIGNUP](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post('/signup', credentials)
                .then(({ data }) => {
                    resolve();
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [LOGOUT](context) {
        context.commit(PURGE_AUTH);
    },
};

const mutations = {
    [SET_AUTH](state, user) {
        state.user = user;
        state.isAuthenticated = true;
        // JwtService.setToken(token);
        cookie.set(USER_COOKIE, user, '1h');
    },
    [PURGE_AUTH](state) {
        state.user = {};
        state.isAuthenticated = false;
        // JwtService.destroyToken();
        cookie.remove(USER_COOKIE);
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};