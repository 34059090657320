import { createApp } from "vue";
import App from '../App.vue';
import store from "../store";
import router from "../router";
import axios from "axios";
import VueAxios from "vue-axios";

import { LOGOUT } from "../store/auth/actions.type";

const app = createApp(App).use(VueAxios, axios);

app.axios.defaults.baseURL = 'https://api-assist.lsmais.com.br/api';

const ApiService = {
    async get(resource) {
        return new Promise((resolve, reject) => {
            app.axios.get(resource).then(data => {
                resolve(data);
            }).catch(data => {
                validator(data.response.status);
                reject(data);
            });
        });
    },

    async post(resource, params) {
        return new Promise((resolve, reject) => {
            app.axios.post(resource, params).then(data => {
                resolve(data);
            }).catch(data => {
                validator(data.response.status);
                reject(data);
            });
        });
    },

    async put(resource, params) {
        return new Promise((resolve, reject) => {
            app.axios.put(resource, params).then(data => {
                resolve(data);
            }).catch(data => {
                validator(data.response.status);
                reject(data);
            });
        });
    },

    async delete(resource) {
        return new Promise((resolve, reject) => {
            app.axios.delete(resource).then(data => {
                    resolve(data);
                }).catch(data => {
                    validator(data.response.status);
                    reject(data);
                });
        });
    }
};

function validator(status) {
    if (status == 401 || status == 498 || status == 46) {
        store.dispatch(LOGOUT);
        router.push("/login");
    }
}

export default ApiService;