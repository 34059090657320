import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import { vMaska } from "maska"

import "./common/api.service";
import 'bootstrap';

import { createI18n } from 'vue-i18n';
import messages from './locales';

const i18n = createI18n({
    warnHtmlMessage: false,
    locale: 'pt',
    fallbackLocale: 'pt',
    messages,
})

router.beforeEach((to, from, next) => {
    if (to.path === "/login") {
        !store.getters.isAuthenticated ? next() : next(from.path);
    } else if (to.path === "/PoliticaPrivacidade") {
        !store.getters.isAuthenticated ? next() : next(from.path);
    } else {
        store.getters.isAuthenticated ? next() : next("/login");
    }
});

const app = createApp(App)

app.use(router)
app.use(store);

app.directive("maska", vMaska)

app.mount('#app')
